import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(mainWebsite)/develocity/solutions/sbt/sbt-power.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["ClientButton"] */ "/app/src/components/Button/Button.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","ButtonIcon"] */ "/app/src/sections/solutions/build-tools/components/hero/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/sections/solutions/build-tools/sbt/feature-tabs-wrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/sections/solutions/build-tools/sbt/learn-more/granularAnalyticInfo.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/solutions/build-tools/sbt/learn-more/scalaInstance.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/sections/solutions/build-tools/sbt/learn-more/scalaObservability.jpg");
