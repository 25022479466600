'use client';

import { AccelerateLogo } from '@/components/Logos';
import FeatureTabs from '../../components/feature-tabs';
import accelerate from '@/sections/solutions/build-tools/components/feature-tabs/accelerate.jpg';
import observe from '@/sections/solutions/build-tools/components/feature-tabs/observe.jpg';
import troubleshoot from '@/sections/solutions/build-tools/components/feature-tabs/troubleshoot.jpg';
import { WrenchIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { TabCardAccordionType } from '@/components/Tabs/TabCard';

const tabItems = [
  { id: 1, text: 'Accelerate', icon: AccelerateLogo },
  { id: 2, text: 'Troubleshoot', icon: WrenchIcon },
  { id: 3, text: 'Observe', icon: ChartBarSquareIcon },
];

const tabContent: TabCardAccordionType[] = [
  {
    id: 1,
    image: accelerate,
    title: 'Accelerate your builds and tests',
    accordionData: [
      {
        title: 'Build Cache',
        body: 'Share and reuse build and test outputs globally, plus access data to easily diagnose cache misses.',
        link: '/develocity/product/build-cache/',
        linkText: 'Learn more',
      },
    ],
  },
  {
    id: 2,
    image: troubleshoot,
    title: 'Troubleshoot failures faster',
    accordionData: [
      {
        title: 'Build Scan® ',
        body: 'Get detailed information for all your builds so you can quickly fix problems and debug performance issues.',
        link: '/develocity/product/build-scan/',
        linkText: 'Learn more',
      },
      {
        title: 'Flaky Test Detection',
        body: ' Identify and fix flaky tests—tests that both pass and fail with no change in the inputs.',
        link: '/develocity/product/flaky-test-detection/',
        linkText: 'Learn more',
      },
      {
        title: 'Failure Analytics',
        body: 'Determine the root cause of build and test failures and prioritize for remediation based on impact.',
        link: '/develocity/product/failure-analytics/',
        linkText: 'Learn more',
      },
    ],
  },
  {
    id: 3,
    image: observe,
    title: 'Observe toolchain health',
    accordionData: [
      {
        title: 'Performance Insights',
        body: 'Get detailed analyses of build and test performance data and trends for both local and CI.',
        link: '/develocity/product/performance-insights/',
        linkText: 'Learn more',
      },
    ],
  },
];

export default function FeatureTabsWrapper() {
  return <FeatureTabs title="Get Develocity’s suite of features" tabItems={tabItems} tabContent={tabContent} />;
}
